<template lang="pug">
  v-row(justify="center")
    v-dialog(
      v-model="dialog"
      eager
      max-width="1300px"
    )
      v-card
        v-toolbar(dark color="primary")
          v-toolbar-title(v-if="user") Client: \#{{user.id}}
        
        v-card-text
          v-container
            form-client(v-model="user" ref="formClient")
        
        //- v-card
        //-   pre {{user}}
        v-card-actions
          v-spacer
          
          v-btn(
            color="blue darken-1"
            text
            @click="dialog = false"
          ) Fechar

          v-btn(
            color="blue darken-1"
            text
            @click="saveClient"
          ) Salvar
        
</template>

<script>
  export default {

    components: {
      'form-client': () => import( '@/views/Clients/components/FormClient' ),
    },
    data: () => ({
      dialog: false,
      user: null
    }),

    methods: {
      open: function( client ){
        this.user = client;
        this.dialog = true;
        this.$refs.formClient.getById( client.id )       
      },
      saveClient: function(){
        let self = this;

        this.api.put(`client/${this.user.id}`, this.user)
          .then(res => {
            if( res.status === 200 ){
              self.$root.toast.show( {message: "Cliente editado com sucesso!" } )
            }
            console.log(res.status)
            // if( res.data ){
            //   this.dialog = false;
            // }
          }).catch( err => {
            self.$root.toast.show( {message: `Ocorreu um erro ao editar o cliente!<br>${err.response.data.message}`, color: 'red', icon: 'mdi-alert' } )
          })
      }
    },
  }
</script>